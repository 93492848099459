import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import MenuItems from "./MenuItems";
import RSMobileMenu from "./RSMobileMenu";
import TopHeader from "./TopBar";
import whiteLogo from "../../../assets/img/logo/dark-logo.png";
import darkLogo from "../../../assets/img/logo/dark-logo.png";
import normalLogo from "../../../assets/img/logo/logo.png";

const Header = (props) => {
  const [searchText, setSearchText] = useState("");
  const {
    headerClass,
    parentMenu,
    secondParentMenu,
    activeMenu,
    mobileNormalLogo,
    TopBar,
    TopBarClass,
    emailAddress,
    phoneNumber,
    Location,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Sticky is displayed after scrolling for 100 pixels
    const toggleVisibility = () => {
      if (window.pageYOffset > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);


  return (
    <React.Fragment>
      <div
        className={
          headerClass
            ? headerClass
            : "full-width-header home8-style4 main-home "
        }
      >
        <header id="rs-header" className="rs-header">
          {TopBar ? (
            <TopHeader
              topBarClass={TopBarClass}
              emailAddress={emailAddress}
              phoneNumber={phoneNumber}
              Location={Location}
            />
          ) : (
            ""
          )}

          <div className={"menu-area menu-sticky"}>
            <div className="container">
              <div className="row y-middle">
                <div className="col-lg-2">
                  <div className="logo-area hidden-md">
                    <Link to="/">
                      <img
                        className="normal-logo"
                        src={
                          window.location.pathname == "/"
                            ? normalLogo
                            : darkLogo
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-8 text-end">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <div className="mobile-menu md-display-block">
                        <Link to="/" className="mobile-normal-logo">
                          <img className="normal-logo" src={whiteLogo} alt="" />
                        </Link>
                        <Link to="/" className="mobile-sticky-logo">
                          <img
                            src={mobileNormalLogo ? mobileNormalLogo : normalLogo}
                            alt="logo"
                          />
                        </Link>

                        <Link
                          to="#"
                          className="rs-menu-toggle readon green-banner menu-toggle-btn"
                          onClick={() => {
                            setMenuOpen(!menuOpen);
                          }}
                        >
                          <i className="fa fa-bars"></i>
                        </Link>

                        <Link
                          to="https://dashboard.syroltech.com/#/login"
                          className="rs-menu-toggle readon green-banner header-btn mt--5  mr--8"
                        >
                          <i className="fa fa-sign-in"></i> Login
                        </Link>
                      </div>
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <MenuItems
                            parentMenu={parentMenu}
                            secondParentMenu={secondParentMenu}
                            activeMenu={activeMenu}
                          />
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2 relative text-end hidden-md">
                  <div className="expand-btn-inner search-icon">
                    <ul className="expand-items">
                      <li className="sidebarmenu-search">
                        <input
                          type="search"
                          className="rs-search search-input"
                          placeholder="Search course..."
                          onChange={(e) => {
                            setSearchText(e.target.value);
                          }}
                          onSubmit={() => {
                            if (searchText != "" && searchText.length > 2) {
                              window.location.href = `/courses?search=${searchText}`;
                            }
                          }}
                          onKeyUp={(e) => {
                            if (searchText != "" && searchText.length > 2) {
                              if (e.key === "Enter") {
                                window.location.href = `/courses?search=${searchText}`;
                              }
                            }
                          }}
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <RSMobileMenu
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            parentMenu={parentMenu}
            secondParentMenu={secondParentMenu}
          />
          <div
            onClick={() => setMenuOpen(false)}
            className={menuOpen ? "body-overlay show" : "body-overlay"}
          ></div>
        </header>
        {/* <CanvasMenu
					canvasClass={CanvasClass ? CanvasClass : "right_menu_togle orange_color hidden-md"}
					canvasLogo={CanvasLogo ? CanvasLogo : darkLogo}
				/> */}
      </div>
    </React.Fragment>
  );
};

export default Header;
