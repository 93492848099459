import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import CourseSingleTwo from "../../components/Courses/CourseSingleTwo";
// Course courseImg
import SectionTitle from "../../components/Common/SectionTitle";
import { CourseService } from "../../services/CourseService";
import Loader from "../../components/Common/Loader";
import Error from "../../components/Error/Error";
import { util } from "../../utilities/util";

const CoursePart = (props) => {
  const [items, setItems] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [searchParams] = useSearchParams();

  const getCourses = (page) => {
    CourseService.get(page, "MAIN")
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;
          setItems(payload.page);
          setTotalPages(payload.totalPages);
          setCurrentPage(payload.currentPage);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  const searchCourses = (searchText) => {
    CourseService.search(searchText, "MAIN")
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          const payload = response.data.data;
          setItems(payload.page);
          setTotalPages(payload.totalPages);
          setCurrentPage(payload.currentPage);
        } else {
          setItems([]);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    const searchText = searchParams.get("search");
    if (searchText) {
      searchCourses(searchText);
    } else {
      getCourses(currentPage);
    }
  }, [currentPage]);

  return (
    <div
      id="rs-popular-course"
      className="rs-popular-courses style1 course-view-style orange-style rs-inner-blog white-bg pt-100 pb-100 md-pt-70 md-pb-80"
    >
      <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          subtitleClass="sub-title primary"
          subtitle="Join Syrol"
          titleClass="title mb-0"
          title="Top Courses"
        />
        <div className="row y-middle mb-50 md-mb-30">
          {isLoading ? (
            <Loader color="#ffc107" size="150px" />
          ) : items.length > 0 ? (
            items.map((item, idx) => {
              return (
                <div className="col-lg-4 col-md-6 mb-30">
                  <CourseSingleTwo
                    id={item.id}
                    courseClass="courses-item"
                    courseImg={item.thumbnail}
                    courseTitle={item.title}
                    coursePrice={util.formatPrice(item.price)}
                    courseCategory={item.duration}
                    userCount={Math.floor(Math.random() * 1000)}
                    userRating="05"
                  />
                </div>
              );
            })
          ) : (
            <Error title="Oops!" message="Courses Not Available" />
          )}
        </div>

        {items.length > 0 ? (
          <div className="pagination-area orange-color text-center mt-30 md-mt-0">
            <ul className="pagination-part">
              <li>
                <Link
                  to="#"
                  onClick={() => {
                    if (currentPage > 0) {
                      setCurrentPage(currentPage - 1);
                      getCourses(currentPage);
                    }
                  }}
                >
                  <i className="fa fa-long-arrow-left"></i>Prev
                </Link>
              </li>
              {Array(totalPages)
                .fill()
                .map((item, idx) => {
                  return (
                    <li className={idx == currentPage ? "active" : ""}>
                      <Link
                        to="#"
                        onClick={() => {
                          getCourses(idx);
                        }}
                      >
                        {idx}
                      </Link>
                    </li>
                  );
                })}

              <li>
                <Link
                  to="#"
                  onClick={() => {
                    if (currentPage <= totalPages) {
                      setCurrentPage(currentPage + 1);
                      getCourses(currentPage);
                    }
                  }}
                >
                  Next <i className="fa fa-long-arrow-right"></i>
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CoursePart;
