import React from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';


import ctaImg from '../../assets/img/ai trade.jpg';

const AITradingSection = () => {

    return (
        <div className="rs-cta section-wrap gray-bg">
            <div className="row align-items-center">
                <div className="col-lg-6">
                    <div className="img-part">
                        <img src={ctaImg} alt="Image" />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="content">
                        <SectionTitle
                            sectionClass="sec-title mb-40"
                            titleClass="title"
                            title="AI Auto Forex Trading"
                            descClass="desc"
                            description={`Welcome to the future of Forex trading with our cutting-edge "Forex AutoTrading Robot" service. Unleash the power of automation and artificial intelligence to get additional profits. We will maximize your profits while you are sleeping. Dive into the world of Forex with confidence.`}
                        />
                        <div className="btn-part">
                            <Link className="readon banner-style uppercase" to="/contact-us">Get in Touch With Us</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AITradingSection;