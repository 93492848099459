import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Faq = () => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-faq-part style1 pt-100 pb-100 md-pt-70 md-pb-70">
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="YLN1Argi7ik"
        onClose={() => {
          openModal();
        }}
      />
      <div className="container">
        <div className="row">
          <div className="col-lg-6 padding-0">
            <div className="main-part">
              <div className="title mb-40 md-mb-14">
                <h2 className="text-part">Frequently Asked Questions</h2>
              </div>
              <div className="faq-content">
                <Accordion className="accordion-style1" preExpanded={"a"}>
                  <AccordionItem className="accordion-item" uuid="a">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Why Syrol Stand out?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Technical Expertise, Quality and Reliability, Innovation
                      and Flexibility, Communication and Collaboration,
                      Cost-Effectiveness, Customer-Centric, Industry Experience,
                      Certifications and Compliance, Scalability and Growth
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="b">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                      What types of software does Syrol develop?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                    Syrol develops custom software solutions, including web applications, mobile apps, enterprise software, and cloud-based solutions.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="c">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                      What methodologies Syrol use for software development?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                    Syrol uses Agile methodologies, with flexibility to adapt to Waterfall or Hybrid approaches.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="d">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        How to get Syrol to build an app for me
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Put together requirements in a document, contact us via
                      our email hello@syroltech.com or phone number or send us
                      message{" "}
                      <a
                        href="https://syroltech.com/contact-us"
                        className="text-danger"
                      >
                        here
                      </a>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="e">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        Can i study at Syrol online?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Yes, Syrol has an intensive learning management platform
                      that is available online, you can learn on the go, and
                      stay connected with your peers and instructors.
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem className="accordion-item" uuid="f">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                        How does Syrol AI trading bot works?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                      Our AI trading bot analyzes the market with our
                      well-standard market strategy, we develop and train the AI
                      to maximize your income while offline. The AI uses any
                      broker of your choice, all you need is to provide your MT4
                      server details and we tell our AI to trade for you.
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" uuid="g">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                      How does Syrol approach software scaling?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                    Syrol uses cloud-based solutions, load balancing, and caching.
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem className="accordion-item" uuid="h">
                    <AccordionItemHeading className="card-header">
                      <AccordionItemButton className="card-link">
                      How does Syrol improve software performance?
                      </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel className="card-body">
                    Syrol optimizes code, database queries, and server configuration and setup Auto-Scaling,  Asynchronous communication, Load Balancing, Caching, Content Delivery Networks (CDNs), Multi-region Deployment. Our scalling solution is based on requirement and system capacity 
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="col-lg-6 padding-0">
            <div className="img-part media-icon">
              <a
                className="popup-videos"
                onClick={() => {
                  openModal();
                }}
              >
                <i className="fa fa-play"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
