import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../components/Layout/Header/Header";
import Footer from "../components/Layout/Footer/Footer";
import Newsletter from "../components/Common/Newsletter";
import ScrollToTop from "../components/Common/ScrollTop";
import OffWrap from "../components/Layout/Header/OffWrap";
import SiteBreadcrumb from "../components/Common/Breadcumb";
import SearchModal from "../components/Layout/Header/SearchModal";
import AboutImage from "../assets/img/about.jpg";
// Image
import favIcon from "../assets/img/fav.png";
import Logo from "../assets/img/logo/dark-logo.png";
import footerLogo from "../assets/img/logo/lite-logo.png";

import bannerbg from "../assets/img/breadcrumbs/2.jpg";
import { util } from "../utilities/util";
import Swal from "sweetalert2";
import { UserService } from "../services/UserService";
import Loader from "../components/Common/Loader";
import NaijaStates from "naija-state-local-government";

const SiwesRegister = () => {
  const [isLoading, setLoading] = useState(false);
  const [localAreas, setLocalAreas] = useState({ lgas: [] });
  const [input, setInput] = useState({
    name: "",
    phoneNumber: "",
    emailAddress: "",
    address: "",
    state: "",
    lga: "",
    role: "SIWES",
    specialization: "",
    password: "",
  });

  const submit = () => {
    if (
      input.name !== "" &&
      input.emailAddress !== "" &&
      input.phoneNumber !== "" &&
      input.state !== "" &&
      input.lga !== "" &&
      input.specialization !== "" &&
      input.password !== ""
    ) {
      if (!util.validateEmail(input.emailAddress)) {
        Swal.fire({
          title: "Validation Failed!",
          text: "Invalid email address",
          icon: "error",
        });
      } else {
        setLoading(true);
        console.log(input);
        UserService.add(input)
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              Swal.fire({
                title: "Success!",
                text: "Application submitted successfully, we will get back to you in 48hrs!",
                icon: "success",
              });
              setInput({
                name: "",
                phoneNumber: "",
                emailAddress: "",
                address: "",
                state: "",
                lga: "",
                role: "SIWES",
                specialization: "",
                password: "",
              });

              setTimeout(() => {
                window.location.href = "/";
              }, 3000);
            } else {
              Swal.fire({
                title: "Oops!",
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((ex) => {
            console.log(ex);
            setLoading(false);
            Swal.fire({
              title: "Oops!",
              text: "Something went wrong",
              icon: "error",
            });
          });
      }
    } else {
      Swal.fire({
        title: "Validation Failed!",
        text: "All fields are required!",
        icon: "error",
      });
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="hello@syroltech.com"
        Location="Abuja, Nigeria"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Register"
        pageName="Register"
        breadcrumbsImg={bannerbg}
      />
      {/* Register Start */}
      <div className="rs-about style1 pb-100">
        <div className="container">
          <div className=" register-section pt-100 pb-100 md-pt-80 md-pb-80 row">
            <div className="col-lg-6 pr-70 md-pr-14">
              <div className="register-box">
                <div className="sec-title text-center mb-30">
                  <h2 className="title mb-10">Apply for SIWES</h2>
                </div>
                <div className="styled-form">
                  <div id="form-messages"></div>
                  <div id="contact-form">
                    <div className="row clearfix">
                      <div className="form-group col-lg-12 mb-25">
                        <input
                          type="text"
                          value={input.name}
                          onChange={(e) =>
                            setInput({ ...input, name: e.target.value })
                          }
                          placeholder="Full Name"
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12">
                        <input
                          type="email"
                          name="email"
                          placeholder="Email address "
                          value={input.emailAddress}
                          onChange={(e) =>
                            setInput({ ...input, emailAddress: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          name="phoneNumber"
                          placeholder="Phone Number"
                          maxLength={11}
                          value={input.phoneNumber}
                          onChange={(e) =>
                            setInput({ ...input, phoneNumber: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <select
                          name="state"
                          value={input.state}
                          onChange={(e) => {
                            setInput({ ...input, state: e.target.value });
                            setLocalAreas(NaijaStates.lgas(e.target.value));
                          }}
                          required
                        >
                          <option value="">Select state...</option>
                          {NaijaStates.states().map((item, idx) => {
                            return <option key={idx}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-12">
                        <select
                          name="lga"
                          value={input.lga}
                          onChange={(e) =>
                            setInput({ ...input, lga: e.target.value })
                          }
                          required
                        >
                          <option value="">Select lga...</option>
                          {localAreas.lgas.map((item, idx) => {
                            return <option key={idx}>{item}</option>;
                          })}
                        </select>
                      </div>
                      <div className="form-group col-lg-12">
                        <select
                          name="sepecialization"
                          value={input.specialization}
                          onChange={(e) =>
                            setInput({
                              ...input,
                              specialization: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select course...</option>
                          <option>Artificial Inteligence</option>
                          <option>Robotics & Embedding System</option>
                          <option>Forex Trading</option>
                          <option>React/Angular/Vue</option>
                          <option>JavaScript/NodeJs/NestJs</option>
                          <option>Java/Springboot/EE</option>
                          <option>Python/ML/AI/DS</option>
                          <option>Cyber Security</option>
                          <option>Cloud Computing</option>
                          <option>Software Testing</option>
                          <option>Ethical Hacking</option>
                          <option>DevOps</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-12">
                        <input
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={input.password}
                          onChange={(e) =>
                            setInput({ ...input, password: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="form-group col-lg-12">
                        <input
                          type="text"
                          name="address"
                          placeholder="Home address "
                          value={input.address}
                          onChange={(e) =>
                            setInput({ ...input, address: e.target.value })
                          }
                          required
                        />
                      </div>

                      <div className="form-group col-lg-12 col-md-12 col-sm-12 text-center">
                        <button
                          type="submit"
                          className="readon register-btn"
                          onClick={isLoading ? null : submit}
                        >
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <span className="txt">Submit</span>
                          )}
                        </button>
                      </div>
                      <div className="form-group col-lg-12 col-md-12 col-sm-12">
                        <div className="users">
                          Already have an account?{" "}
                          <Link to="https://dashboard.syroltech.com/#/login">
                            Sign In
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
              <div className="img-part">
                <img
                  className="about-main"
                  src={AboutImage}
                  alt="About Image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
      <SearchModal />
    </React.Fragment>
  );
};

export default SiwesRegister;
