import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

const RSMobileMenu = ({
  setMenuOpen,
  menuOpen,
  parentMenu,
  secondParentMenu,
  headerFullWidth,
}) => {
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [courses, setCourses] = useState(false);
  const [faqs, setFaqs] = useState(false);
  const [contact, setContact] = useState(false);

  return (
    <div
      className={
        headerFullWidth ? "container-fluid relative" : "container relative"
      }
    >
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li
              className={
                home
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/"
                className={parentMenu === "home" ? "active-menu" : ""}
              >
                Home
              </Link>
            </li>
            <li
              className={
                about
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/about-us"
                className={parentMenu === "about" ? "active-menu" : ""}
              >
                About Us
              </Link>
            </li>
            <li
              className={
                services
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/services"
                className={parentMenu === "services" ? "active-menu" : ""}
              >
                Services
              </Link>
            </li>
            <li
              className={
                courses
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/courses"
                className={parentMenu === "courses" ? "active-menu" : ""}
              >
                Courses
              </Link>
            </li>
            <li
              className={
                faqs
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/faq"
                className={parentMenu === "faqs" ? "active-menu" : ""}
              >
                FAQs
              </Link>
            </li>
            <li
              className={
                contact
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="/contact"
                className={parentMenu === "contact" ? "active-menu" : ""}
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
