import { http } from '../utilities/http'
import { config } from '../utilities/configs'

let AnnouncementService = {
  get: async (page) => {
    let url = config.baseUrl + '/announcement/list'
    if (page) url += `?page${page}`

    const resp = await http.get(url)
    return resp
  },
}

export { AnnouncementService }
