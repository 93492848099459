import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

// Image
import aboutImg from "../../assets/img/about/history.png";

const AboutTab = () => {
  let tab1 = "History",
    tab2 = "Mission",
    tab3 = "Vision";
  const tabStyle = "nav nav-tabs histort-part";

  return (
    <div className="rs-about style1  md-pt-70 md-pb-70">
      <div className="container">
        <Tabs>
          <div className="row align-items-center">
            <div className="col-lg-6 padding-0 md-pl-14 md-pr-14 md-mb-30 relative">
              <div className="img-part">
                <img src={aboutImg} alt="About Image" />
              </div>
              <TabList className={tabStyle}>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-banknote"></i>
                    </span>
                    {tab1}
                  </button>
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-flower"></i>
                    </span>
                    {tab2}
                  </button>@
                </Tab>
                <Tab>
                  <button>
                    <span className="icon-part">
                      <i className="flaticon-analysis"></i>
                    </span>
                    {tab3}
                  </button>
                </Tab>
              </TabList>
            </div>
            <div className="col-lg-6 pl-120 md-pl-14">
              <TabPanel>
                <div className="about-history">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol History</h2>
                    <div className="desc">
                      <p>
                        Founded in 2019, Syrol Technologies Limited history is
                        deeply rooted in MTN Nigeria. Aliyu Rabiu Abdul (known
                        as Rabal), was a staff at MTN. With a first-class degree
                        in Information Technology from NIMS University Jaipur
                        Rajasthan India and a second degree in Artificial
                        Intelligence from the University of Zambia, he
                        recognized Africa tech industry's pressing need for
                        first class talent.
                      </p>
                      <p className="mt-3">
                        Inspired by MTN's commitment to technological
                        advancement, Rabal initially created a free online
                        learning platform to share his software engineering
                        expertise. As demand grew, he established Syrol
                        Technologies Limited, officially registering the company
                        with CAC in Nigeria in 2019. His experience in the tech
                        industry influenced Syrol's branding and led to a
                        valuable partnership with tech giants.
                      </p>
                      <p className="mt-3">
                        Starting in Lagos, Syrol has since expanded to Abuja and
                        Kano State. The company continues its mission to bridge
                        the tech skills gap in Africa, leveraging its several
                        partnerships, including MTN, Union Bank, CodeBeans, and
                        Decagon to enhance its training programs and reach.
                        Syrol's exponential growth reflects its commitment to
                        empowering individuals with crucial technological
                        skills, mirroring its vision of driving digital
                        transformation across the continent.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-mission">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol Mission</h2>
                    <div className="desc">
                      At Syrol Technologies, our core mission is to evolve into
                      a global brand with a recognized presence and an
                      unparalleled service model across numerous countries. We
                      strive to create an open and accessible information
                      technology systems to assist individuals and businesses
                      turn into a digital marketplace.
                      <p className="mt-3">
                        Our primary goal is to bridge the gap between
                        technological advancement and practical application,
                        ensuring that our clients and students are well-equipped
                        to thrive in an increasingly digital world.
                      </p>
                      <p className="mt-3">
                        Syrol Technologies envisions itself as the leading
                        software research, development, and training firm in
                        Africa. We aim to set new standards in technological
                        innovation and education, contributing significantly to
                        the continent's digital transformation and economic
                        growth.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="about-admin">
                  <div className="sec-title mb-24">
                    <h2 className="title">Syrol Vision</h2>
                    <div className="desc">
                      Our vision is to become the preeminent Software Solution
                      provider, Job Placement Network, and Hands-on Training
                      company in the IT sector, continuously advancing our
                      market position. We recognize that our clients' success
                      directly fuels our own growth, and we are committed to
                      helping them achieve their business objectives.
                      <p className="mt-3">
                        At Syrol, we prioritize accuracy and superior quality in
                        all our endeavors. We aspire to be recognized as a
                        dependable, innovative, and user-friendly software
                        service provider in the IT industry. Our goal is to
                        transform dreams and ideas into flawless realities,
                        fostering innovation without compromise.
                      </p>
                      <p className="mt-3">
                        By aligning our success with that of our clients and
                        students, we aim to create a robust ecosystem of
                        technological advancement and professional development,
                        solidifying our position as a leader in Africa's digital
                        transformation.
                      </p>
                      <p className="mt-3">
                        We create and innovate by bringing dreams and ideas into
                        reality with no default.
                      </p>
                    </div>
                  </div>
                </div>
              </TabPanel>
            </div>
          </div>
        </Tabs>
      </div>
    </div>
  );
};

export default AboutTab;
