import { Link } from "react-router-dom";

const SingleTeam = (props) => {
  const {
    teamClass,
    Image,
    Title,
    Designation,
    facebook,
    twitter,
    instagram,
    linkedin,
  } = props;
  return (
    <div className={teamClass ? teamClass : "team-item"}>
      <img src={Image} alt={Title} />
      <div className="content-part">
        <h4 className="name">
          <Link to="/team/team-single">{Title ? Title : "Jhon Pedrocas"}</Link>
        </h4>
        <span className="designation">
          {Designation ? Designation : "Professor"}
        </span>
        <ul className="social-links">
          <li>
            <Link to={facebook}>
              <i className="fa fa-facebook-f"></i>
            </Link>
          </li>
          <li>
            <Link to={twitter}>
              <i className="fa fa-twitter"></i>
            </Link>
          </li>
          <li>
            <Link to={linkedin}>
              <i className="fa fa-linkedin"></i>
            </Link>
          </li>
          <li>
            <Link to={instagram}>
              <i className="fa fa-instagram"></i>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SingleTeam;
