import React, { useState } from "react";
import { util } from "../../utilities/util";
import Swal from "sweetalert2";
import { ContactUsService } from "../../services/ContactUsService";
import Loader from "../Common/Loader";

const ContactForm = (props) => {
  const { submitBtnClass, btnText } = props;
  const [isLoading, setLoading] = useState(false);
  const [input, setInput] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const submit = () => {
    if (
      input.name !== "" &&
      input.email !== "" &&
      input.phone !== "" &&
      input.message !== ""
    ) {
      if (!util.validateEmail(input.email)) {
        Swal.fire({
          title: "Validation Failed!",
          text: "Invalid email address",
          icon: "error",
        });
      } else {
        setLoading(true);
        ContactUsService.add(input)
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              Swal.fire({
                title: "Success!",
                text: "Message sent successfully, and we will get back to you in 48 hrs",
                icon: "success",
              });
              setInput({
                name: "",
                email: "",
                phone: "",
                subject: "",
                message: "",
              });
            } else {
              Swal.fire({
                title: "Oops!",
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((ex) => {
            console.log(ex);
            setLoading(false);
            Swal.fire({
              title: "Oops!",
              text: "Something went wrong",
              icon: "error",
            });
          });
      }
    } else {
      Swal.fire({
        title: "Validation Failed!",
        text: "All fields are required!",
        icon: "error",
      });
    }
  };

  return (
    <div id="contact-form">
      <div className="row">
        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="name"
            name="name"
            value={input.name}
            placeholder="Name"
            required
            onChange={(e) => setInput({ ...input, name: e.target.value })}
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            value={input.email}
            placeholder="Email"
            required
            onChange={(e) => setInput({ ...input, email: e.target.value })}
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="phone"
            value={input.phone}
            name="phone"
            placeholder="Phone Number"
            required
            onChange={(e) => setInput({ ...input, phone: e.target.value })}
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="subject"
            value={input.subject}
            name="subject"
            placeholder="Subject"
            onChange={(e) => setInput({ ...input, subject: e.target.value })}
            required
          />
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            value={input.message}
            placeholder="Your message Here"
            onChange={(e) => setInput({ ...input, message: e.target.value })}
            required
          ></textarea>
        </div>
      </div>
      <div className="btn-part">
        <button
          className={
            submitBtnClass ? submitBtnClass : "readon learn-more submit"
          }
          type="submit"
          onClick={isLoading ? null : submit}
        >
          {isLoading ? <Loader/> : "Submit Now"}
        </button>
      </div>
    </div>
  );
};

export default ContactForm;
