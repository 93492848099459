import React from "react";
import { Link } from "react-router-dom";
import { util } from "../../../utilities/util";

const OverviewPart = (props) => {
  const { course } = props;

  return (
    <div className="content white-bg pt-30">
      <div className="course-overview">
        <div className="inner-box">
          <h4>{course.title}</h4>

          <div
            dangerouslySetInnerHTML={{
              __html: util.formatText(course.description),
            }}
          />

          <div className="btn-part mt-30">
            <Link
              to={course.curriculum}
              target="_blank"
              className=" readon2 orange"
            >
              <i className="fa fa-download"></i> View Curriculum
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPart;
