import React from "react";
import { Link } from "react-router-dom";
import { util } from "../../utilities/util";

const CourseSingleTwo = (props) => {
  const {
    id,
    courseClass,
    courseImg,
    courseTitle,
    coursePrice,
    courseCategory,
    userCount,
    userRating,
  } = props;
  return (
    <div className={courseClass ? courseClass : "courses-item"}>
      <div className="img-part">
        <Link to={`/course/details/${id}`}>
          <img
            src={courseImg}
            alt={courseTitle}
            className="course-pic"
            onError={util.getDefaultAvatar}
          />
        </Link>
      </div>
      <div className="content-part">
        <ul className="meta-part">
          <li>
            <span className="price">{coursePrice ? coursePrice : "0.00"}</span>
          </li>
          <li>
            <span className="categorie">
              {courseCategory ? courseCategory : "IT"}
            </span>
          </li>
        </ul>
        <h3 className="title">
          <Link to={`/course/details/${id}`}>
            {courseTitle ? courseTitle : "Unknown"}
          </Link>
        </h3>
        <div className="bottom-part">
          <div className="info-meta">
            <ul>
              <li className="user">
                <i className="fa fa-user"></i>{" "}
                {userCount ? userCount : Math.floor(Math.random() * 11001)}
              </li>
              <li className="ratings">
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <i className="fa fa-star"></i>
                <span>({userRating ? userRating : "05"})</span>
              </li>
            </ul>
          </div>
          <div className="btn-part">
            <Link to={"/course/details/" + id}>
              {props.btnText}
              <i className="flaticon-right-arrow"></i>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseSingleTwo;
