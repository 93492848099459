import { http } from "../utilities/http";
import { config } from "../utilities/configs";
import { util } from "../utilities/util";

let NewsletterService = {
  add: async (request) => {
    let url = config.baseUrl + "/newsletter";
    const resp = await http.post(url, request);
    return resp;
  },
};

export { NewsletterService };
