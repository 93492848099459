import React from "react";
import { Link } from "react-router-dom";
import { util } from "../../utilities/util";

const SingleProduct = (props) => {
  const {
    productClass,
    productImg,
    productLink,
    productDate,
    productTitle,
    productDescription,
    btnText,
  } = props;
  return (
    <div className={productClass ? productClass : "event-item"}>
      <div className="event-short">
        <div className="featured-img">
          <img src={productImg} alt="Image" onError={util.getDefaultAvatar} />
        </div>

        <div className="content-part">
          <h4 className="title">
            <Link to={productLink} target="_blank">
              {productTitle
                ? productTitle
                : "Educational Technology and Mobile Learning"}
            </Link>
          </h4>
          {productDescription ? (
            <p className="text">{productDescription}</p>
          ) : (
            ""
          )}
          <div className="event-btm">
            <div className="date-part">
              <div className="date">
                <i className="fa fa-calendar-check-o"></i>
                {productDate ? productDate : "July 24, 2020"}
              </div>
            </div>
            <div className="btn-part">
              <Link target="_blank" to={productLink}>
                <i className="fa fa-eye"></i> &nbsp;
                {btnText ? btnText : "View"}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
