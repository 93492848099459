import React from 'react';
import Slider from "react-slick";
import SectionTitle from '../../components/Common/SectionTitle';
import SingleTestimonial from '../../components/Testimonial/SingleTestimonial';


import quote from '../../assets/img/testimonial/style5/quote2.png';
import author1 from '../../assets/img/testmonial1.jpg';
import author2 from '../../assets/img/testmonial2.jpeg';
import author3 from '../../assets/img/testmonial3.jpg';
import author4 from '../../assets/img/salihu.jpg';
import author5 from '../../assets/img/ali.png';

const Testimonial = () => {

    const testimonialSettings = {
        dots: true,
        centerMode: false,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                }
            }
        ]
    };


    return (
        <React.Fragment>
            <div className="rs-testimonial home-style1 pt-100 pb-150 md-pt-70 md-pb-70">
                <div className="container">
                    <SectionTitle
                        sectionClass="sec-title mb-50 md-mb-30 text-center"
                        subtitleClass="sub-title primary"
                        subtitle="Testimonial"
                        titleClass="title mb-0"
                        title="What Clients Saying"
                    />
                    <Slider {...testimonialSettings}>
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author1}
                            Title="Victor Ngonidzashe"
                            Designation="Vice Chancellor of Midlands State University (MSU)"
                            Description="Syrol's expertise has revolutionized our financial management. Streamlined processes, enhanced transparency, and improved decision-making."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author2}
                            Title="Mr. Jegede Emmanuel"
                            Designation="ABUCOOP Microfinance Bank Limited"
                            Description="Abucoop applauds Syrol Technologies for their exceptional financial software solution. User-friendly, robust, and tailored to our needs."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author5}
                            Title="Alhaji Ali Nuhu"
                            Designation="C.E.O Sagama Construction & Engineering LTD"
                            Description="I highly recommend Syrol Technologies to any organization seeking reliable and innovative construction and engineering related software solutions."
                        />
                        <SingleTestimonial
                            itemClass="testi-item"
                            quoteImage={quote}
                            authorImage={author4}
                            Title="Salihu Umar"
                            Designation="Chairman of AMMON"
                            Description="Syrol's POS payment software has transformed AMMON's operations. Efficient, secure, and customer-friendly. Highly recommended!"
                        />
                      
                    </Slider>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Testimonial