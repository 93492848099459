import axios from "axios";
import { util } from "./util";

function HttpRequests() {
  this.post = async (url, data) => {
    return await axios.post(url, data, util.getHeaders());
  };
  this.get = async (url) => {
    return await axios.get(url, util.getHeaders());
  };
}

const http = new HttpRequests();
export { http };
