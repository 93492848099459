import { config } from "../utilities/configs";
import { http } from "../utilities/http";

let UserService = {
  add: async (request) => {
    const resp = await http.post(config.baseUrl + "/user/signup", request);
    return resp;
  },
};

export { UserService };
