import { useState } from "react";
import { util } from "../../../utilities/util";
import Swal from "sweetalert2";
import { NewsEventService } from "../../../services/NewsEventService";
import Loader from "../../Common/Loader";
import { NewsletterService } from "../../../services/NewsletterService";

const SubscribeForm = () => {
  const [isLoading, setLoading] = useState(false);
  const [input, setInput] = useState({
    email: "",
  });

  const submit = () => {
    if (input.email !== "") {
      if (!util.validateEmail(input.email)) {
        Swal.fire({
          title: "Validation Failed!",
          text: "Invalid email address",
          icon: "error",
        });
      } else {
        setLoading(true);
        NewsletterService.add(input)
          .then((response) => {
            setLoading(false);
            if (response.data.success) {
              Swal.fire({
                title: "Success!",
                text: "Subscribed successfully",
                icon: "success",
              });
              setInput({
                email: "",
              });
            } else {
              Swal.fire({
                title: "Oops!",
                text: response.data.message,
                icon: "error",
              });
            }
          })
          .catch((ex) => {
            console.log(ex);
            setLoading(false);
            Swal.fire({
              title: "Oops!",
              text: "Something went wrong",
              icon: "error",
            });
          });
      }
    }
  };

  return (
    <div>
      <input
        style={{ padding: 10 }}
        type="email"
        value={input.email}
        onChange={(e) => setInput({ email: e.target.value })}
        placeholder="Your email"
      />
      <br />
      <button
        className="btn ml-3"
        style={{  padding: 15, minWidth:'100px' }}
        onClick={submit}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <span>
            Subscribe <i className="fa fa-long-arrow-right"></i>
          </span>
        )}
      </button>
    </div>
  );
};

export default SubscribeForm;
