import React from "react";
import { Link } from "react-router-dom";
import { util } from "../../utilities/util";

const SingleEventTwo = (props) => {
  const {
    eventClass,
    eventImg,
    eventDescription,
    eventDate,
    eventSchedule,
    eventTitle,
    eventDesc,
  } = props;
  return (
    <div className={eventClass ? eventClass : "event-item"}>
      <div className="event-item">
        <div className="event-short">
          <div className="featured-img">
            <img src={eventImg} alt="Image" onError={util.getDefaultAvatar} />
            <div className="dates">
              {eventDate ? eventDate : ""}
            </div>
          </div>
          <div className="content-part">
            <h4 className="title">
              <span>
                {eventTitle
                  ? eventTitle
                  : ""}
              </span>
            </h4>
            {eventDesc ? <p className="text">{eventDesc}</p> : ""}
            <div className="time-sec">
              {eventSchedule ? (
                <div className="timesec">
                  <i className="fa fa-clock-o"></i>
                  {eventSchedule}
                </div>
              ) : (
                ""
              )}
              <div className="address">
                <i className="fa fa-info"></i>{" "}
                {eventDescription ? eventDescription : ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleEventTwo;
