import { config } from "../utilities/configs";
import { http } from "../utilities/http";

let CourseEnrollmentService = {
  add: async (request) => {
    const resp = await http.post(config.baseUrl + "/course-enrollment/register", request);
    return resp;
  },
};
export { CourseEnrollmentService };
