import { config } from "../utilities/configs";
import { http } from "../utilities/http";
let CourseService = {
  get: async (page, category) => {
    let url = config.baseUrl + `/course/list/public?page=${page ? page : 0}`;
    if (category) url += `&category=${category}`;
    const resp = await http.get(url);
    return resp;
  },
  getCourseById:async (id) => {
    let url = config.baseUrl + `/course/detail/${id}`;
    const resp = await http.get(url);
    return resp;
  },
  search: async (searchValue, category) => {
    let url = config.baseUrl + `/course/search/public?q=${searchValue}`;
    if (category) url += `&category=${category}`;
    const resp = await http.get(url);
    return resp;
  },
};

export { CourseService };
