import React, { useEffect, useState } from "react";
import CourseSidebar from "./CourseSidebar";
import CourseDetailsTab from "./CourseDetailsTab";
import { CourseService } from "../../../services/CourseService";
import { useParams } from "react-router-dom";
import Loader from "../../../components/Common/Loader";
import Error from "../../../components/Error/Error";

const CourseDetailsPart = () => {
  const [course, setCourse] = useState({});
  const [isLoading, setLoading] = useState(true);
  const { id } = useParams();

  const getCourse = (id) => {
    CourseService.getCourseById(id)
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        if (response.data.success) {
          setCourse(response.data.data);
        }
      })
      .catch((ex) => {
        console.log(ex);
        setLoading(false);
      });
  };

  useEffect(() => {
    getCourse(id);
  }, []);

  return (
    <React.Fragment>
      <div className="intro-section gray-bg pt-94 pb-100 md-pt-80 md-pb-80 loaded">
        <div className="container">
          {isLoading ? (
            <Loader color="#ffc107" size="150px" />
          ) : course ? (
            <div className="row clearfix">
              <div className="col-lg-8 md-mb-50">
                <CourseDetailsTab course={course} />
              </div>
              <div className="video-column col-lg-4">
                <CourseSidebar course={course} />
              </div>
            </div>
          ) : (
            <Error title="Course Details" message="Course not found" />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CourseDetailsPart;
