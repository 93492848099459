import { config } from "../utilities/configs";
import { http } from "../utilities/http";
let CourseReviewService = {
  get: async (page, id) => {
    let url = config.baseUrl + `/course-review/list/${id}?page=${page ? page : 0}`;
    const resp = await http.get(url);
    return resp;
  },
  search: async (searchValue, id) => {
    let url = config.baseUrl + `/course-review/search/${id}?q=${searchValue}`;
    const resp = await http.get(url);
    return resp;
  },
};

export { CourseReviewService };
