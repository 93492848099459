import React from 'react';

import SectionTitle from '../../components/Common/SectionTitle';

// About Image
import AboutImage from '../../assets/img/about.jpg';

const AboutText = (props) => {

    return (
        <div className="rs-about style1 pt-82 pb-100 md-pt-50 md-pb-60">            
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 order-last padding-0 md-pl-14 md-pr-14 md-mb-30">
                        <div className="img-part">
                            <img className="about-main" src={AboutImage} alt="About Image" />
                        </div>
                    </div>
                    <div className="col-lg-6 pr-70 md-pr-14">
                        <div className="about-content">
                            {/* Section Title Start */}
                            <SectionTitle
                                sectionClass="sec-title mb-44 md-mb-0"
                                subtitleClass="sub-title orange"
                                subtitle="About Syrol"
                                titleClass="title mb-16"
                                title="Fast Growing IT Services Provider in Africa"
                                descClass="bold-text mb-22"
                                description="Syrol Technologies represents a dynamic and exceptional assembly of highly skilled, professional IT experts dedicated to delivering scalable IT services. Our offerings extend beyond software development to include comprehensive, hands-on computer professional training."
                                secondDescClass="desc"
                                secondDescription="In an era of rapid technological advancement, large-scale innovations driven by software applications, mobile technologies, and artificial intelligence are catalyzing change across all sectors, not just the IT industry. The technology sector is experiencing unprecedented growth, revolutionizing conventional lifestyles, enhancing living standards, and significantly contributing to the global economy. Research indicates that Computer Science (CS) is poised to dominate the future job market. Projections suggest that by 2024, there will be approximately 4.4 million jobs in computer and information technology occupations. Consequently, organizations across industries are increasingly seeking tech-proficient staff to meet their evolving needs."
                            />
                            {/* Section Title End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AboutText;