import defaultIMG from "../assets/img/no-image.jpg";

function Util() {
  this.getDefaultAvatar = (e) => {
    const img = e.target;
    img.src = defaultIMG;
  };
  this.getHeaders = () => {
    return {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json; charset=utf-8",
      },
    };
  };
  this.validateEmail = (emailAdress) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (emailAdress && emailAdress.match(regex)) return true;
    else return false;
  };

  this.formatPrice = (number) => {
    let result = "₦" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return result;
  };
  this.formatText = (text) => {
    return text.replaceAll(/\n/g, "<br/>");
  };
}
const util = new Util();
export { util };
