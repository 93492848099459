import React from "react";
import { Link } from "react-router-dom";

import defaultLogo from "../../../assets/img/logo/logo.png";
const Footer = (props) => {
  const { footerClass, footerTopClass, footerLogo, footerBG, footerColor } =
    props;

  return (
    <footer id="rs-footer" className={footerClass ? footerClass : "rs-footer"}>
      <div className={footerTopClass ? footerTopClass : "footer-top"}>
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">More About Syrol</h4>
              <p className="text-white">
                Syrol is a leading IT service provider delivering cutting-edge
                solutions to businesses in Nigeria, India, and neighboring
                African countries. Empowering businesses to succeed in the
                digital landscape through innovative, scalable, and secure IT
                solutions.
              </p>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">Quick Links</h4>
              <ul className="site-map">
                <li>
                  <Link to="https://dashboard.syroltech.com/#/learning-materials">
                    Learning Materials
                  </Link>
                </li>
                <li>
                  <Link to="https://dashboard.syroltech.com/#/announcement">
                    Annoucements
                  </Link>
                </li>
                <li>
                  <Link to="https://dashboard.syroltech.com/#/enroll-courses">
                    Enrolled Courses
                  </Link>
                </li>
                <li>
                  <Link to="https://dashboard.syroltech.com/">Assignments</Link>
                </li>
                <li>
                  <Link to="https://dashboard.syroltech.com/">Timetable</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget md-mb-50">
              <h4 className="widget-title">Our Inventions</h4>
              <ul className="site-map">
                <li>
                  <Link to="https://bayascript.com">Bayascript</Link>
                </li>
                <li>
                  <Link to="https://pypi.org/project/jlod/">
                    JLoad Database
                  </Link>
                </li>
                <li>
                  <Link to="https://pypi.org/project/FTTCrypt/">
                    File to Text Cryptography
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 footer-widget">
              <h4 className="widget-title">Address</h4>
              <ul className="address-widget address2">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    Ihesiaba Court, Ishaya Shekari Crescent by Federal Staff
                    Housing Estate, Gwarinpa, Abuja, FCT Nigeria
                  </div>
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <a href="tel:+2348064160204">(+234)8064160204</a>
                    <a href="tel:+2349043205555">(+234)9043205555</a>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <a href="hello@syroltech.com">hello@syroltech.com</a>
                    <a href="recruitment@syroltech.com">
                      recruitment@syroltech.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row y-middle">
            <div className="col-lg-4 md-mb-20">
              <div className="footer-logo">
                <Link to="/" as="/">
                  <img src={defaultLogo} alt="Logo" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 md-mb-20">
              <div className="copyright text-center md-text-left">
                <p>© 2022 All Rights Reserved. Syrol Technologies Ltd</p>
              </div>
            </div>
            <div className="col-lg-4 text-right md-text-left">
              <ul className="footer-social">
                <li>
                  <Link to="https://facebook.com/syroltech">
                    <i className="fa fa-facebook-f"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://x.com/syroltech">
                    <i className="fa fa-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://linkedin.com/syroltech">
                    <i className="fa fa-linkedin"></i>
                  </Link>
                </li>
                <li>
                  <Link to="https://instagram.com/syroltech">
                    <i className="fa fa-instagram"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
