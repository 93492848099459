import React from 'react';

import aboutImg from '../../assets/img/choose/home12/1.png';
import icon1 from '../../assets/img/choose/home12/icon/1.png';
import icon2 from '../../assets/img/choose/home12/icon/2.png';
import icon3 from '../../assets/img/choose/home12/icon/3.png';

import bgImg from '../../assets/img/bg/home12/why-learn-bg.jpg';

const bgStyle = {
    backgroundImage:`url(${bgImg})`
}

const WhyChooseUs = () => {

    return (
        <div className="why-choose-us style3" style={bgStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 md-mb-40">
                        <div className="img-part">
                            <img src={aboutImg} alt="" />                        
                        </div>
                    </div>
                    <div className="col-lg-6 pl-60 md-pl-14">
                        <div className="sec-title3 mb-30">
                            <h2 className=" title new-title margin-0 pb-14">Join the Syrol Advantage</h2>
                            <div className="new-desc">Discover how our innovative software development and expert maintenance services can transform your business</div>
                        </div>
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon1} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title">Personalized Approach</h2>
                                <p className="services-txt">Understanding unique business needs and goals </p>
                            </div>
                        </div> 
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon2} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title"> Quality Assurance</h2>
                                <p className="services-txt">Rigorous security and performance testing and quality control processes.</p>
                            </div>
                        </div> 
                        <div className="services-part mb-20">
                            <div className="services-icon">
                                <img src={icon1} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title">Technical Expertise</h2>
                                <p className="services-txt">In-depth knowledge of latest technologies and frameworks. </p>
                            </div>
                        </div> 
                        <div className="services-part">
                            <div className="services-icon">
                                <img src={icon3} alt="" />
                            </div>
                            <div className="services-text">
                                <h2 className="title">Continuous Improvement</h2>
                                <p className="services-txt">Regular updates and maintenance for optimal performance</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default WhyChooseUs;