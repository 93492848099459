import React from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleServiceSeven from "../../components/Service/SingleServiceSeven";

import serviceImg1 from "../../assets/img/maintainace.png";
import serviceImg2 from "../../assets/img/cyber.png";
import serviceImg3 from "../../assets/img/robotics.png";

import serviceImg4 from "../../assets/img/software development.png";
import serviceImg5 from "../../assets/img/vocational training.png";
import serviceImg6 from "../../assets/img/devops.png";

import bgImg from "../../assets/img/bg/home12/services-bg.jpg";

const bgStyle = {
  backgroundImage: `url(${bgImg})`,
};

const Service = () => {
  return (
    <React.Fragment>
      <div
        id="rs-services"
        className="rs-services home12-style"
        style={bgStyle}
      >
        <div className="container">
          <SectionTitle
            sectionClass="sec-title4 text-center mb-50"
            subtitleClass="sub-title"
            subtitle="Know More About"
            titleClass="title"
            title="What we do"
          />

          <div className="row">
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg4}
                serviceTitle="Software Development"
                serviceDesc="We design and develop software solutions engineered to deliver optimal results. Our approach focuses on maximizing outcomes while maintaining cost-effectiveness in line with current market standards"
              />
            </div>
            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg1}
                serviceTitle="Software Maintenance"
                serviceDesc="We ensure your application run seamlessly, efficiently, and securely. Our maintenance and operational support services guarantee optimal performance, minimizing downtime and maximizing productivity."
              />
            </div>
           

            <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg3}
                serviceTitle="Robotics & AI Bots"
                serviceDesc="At Syrol, we pioneer the future of intelligent automation by designing, developing, and deploying cutting-edge robots and AI bots. Our expert team crafts tailored solutions that integrate AI and ML."
              />
            </div>
           
          </div>
          <div className="row  mt-20">
          <div className="col-lg-4 md-mb-30">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg5}
                serviceTitle="Vocational Training"
                serviceDesc="We provide comprehensive, hands-on IT-related training and mentorship programs. Our services cater to governmental organizations, NGOs, and individuals, offering instruction from zero to advanced"
              />
            </div>
            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg6}
                serviceTitle="System Design & DevOps"
                serviceDesc="Our System Design and DevOps services empower businesses to achieve operational efficiency, scalability, and reliability. Our team of experts crafts tailored solutions to meet your unique needs."
              />
            </div>

            <div className="col-lg-4">
              <SingleServiceSeven
                serviceClass="services-item"
                serviceImg={serviceImg2}
                serviceTitle="IT Security & Audit"
                serviceDesc="In today's digital landscape, cyber threats and data breaches can have devastating consequences. Ensure your organization's security and compliance with Syrol's expert Cyber Security, Risk Management, and IT Audit services."
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Service;
