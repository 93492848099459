import React, { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ReviewPart from "./ReviewPart";
import OverviewPart from "./OverviewPart";
import InstructorPart from "./InstructorPart";

const CourseDetailsTab = (props) => {
  let tab1 = "Overview",
    tab2 = "Instructors",
    tab3 = "Reviews";
  const tabStyle = "intro-tabs tabs-box";
  const { course } = props;

  useEffect(() => {
    console.log(course);
  }, []);

  return (
    <div className="intro-info-tabs">
      <Tabs>
        <TabList className={tabStyle}>
          <Tab>
            <button>{tab1}</button>
          </Tab>
          {course && course.allocations && course.allocations.length > 0 ? (
            <Tab>
              <button>{tab2}</button>
            </Tab>
          ) : (
            <></>
          )}
          <Tab>
            <button>{tab3}</button>
          </Tab>
        </TabList>

        <TabPanel>
          <OverviewPart course={course} />
        </TabPanel>

        {course && course.allocations && course.allocations.length > 0 ? (
          <TabPanel>
            <InstructorPart allocations={course?.allocations || []} />
          </TabPanel>
        ) : (
          <></>
        )}

        <TabPanel>
          <ReviewPart course={course} />
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default CourseDetailsTab;
