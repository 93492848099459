import React from "react";
import { Helmet } from "react-helmet";
import OffWrap from "../../components/Layout/Header/OffWrap";
import Footer from "../../components/Layout/Footer/FooterStyleOne";
import SearchModal from "../../components/Layout/Header/SearchModal";
import ScrollToTop from "../../components/Common/ScrollTop";
import Newsletter from "../../components/Common/Newsletter";
import ContactMain from "./ContactMain";
import Header from "../../components/Layout/Header/Header";
// Image
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import favIcon from "../../assets/img/fav.png";

const Contact = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="others"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="hello@syroltech.com"
        Location="Abuja, Nigeria"
      />
      <ContactMain />
      <Newsletter
        sectionClass="rs-newsletter style1 orange-color event-bg mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />
      {/* Newsletter Section End */}

      {/* Footer */}
      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />
      {/* Footer */}

      {/* SearchModal */}
      <SearchModal />
      {/* SearchModal */}

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default Contact;
