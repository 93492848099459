import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/Footer/Footer";
import OffWrap from "../../components/Layout/Header/OffWrap";
import SearchModal from "../../components/Layout/Header/SearchModal";
import Newsletter from "../../components/Common/Newsletter";
import ScrollToTop from "../../components/Common/ScrollTop";
import SiteBreadcrumb from "../../components/Common/Breadcumb";

// Image
import favIcon from "../../assets/img/fav.png";
import Logo from "../../assets/img/logo/dark-logo.png";
import footerLogo from "../../assets/img/logo/lite-logo.png";
import bannerbg from "../../assets/img/breadcrumbs/2.jpg";

// Event Images
import eventImg1 from "../../assets/img/p1.png";
import eventImg2 from "../../assets/img/p2.png";
import eventImg3 from "../../assets/img/p3.png";
import SingleProduct from "../../components/Events/SingleProduct";
import SectionTitle from "../../components/Common/SectionTitle";

const Portpolio = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link rel="icon" href={favIcon} />
      </Helmet>
      <OffWrap />
      <Header
        parentMenu="pages"
        secondParentMenu="event"
        headerNormalLogo={Logo}
        headerStickyLogo={Logo}
        CanvasLogo={Logo}
        mobileNormalLogo={Logo}
        CanvasClass="right_menu_togle hidden-md"
        headerClass="full-width-header header-style1 home8-style4"
        TopBar="enable"
        TopBarClass="topbar-area home8-topbar"
        emailAddress="hello@syroltech.com"
        Location="Abuja, Nigeria"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        pageTitle="Event Two"
        pageName="Event"
        breadcrumbsImg={bannerbg}
      />
      {/* breadcrumb-area-End */}

      <div className="rs-event modify1 orange-style pt-100 pb-100 md-pt-80 md-pb-80">
        <div className="container">
        <SectionTitle
          sectionClass="sec-title mb-50 md-mb-30 text-center"
          subtitleClass="sub-title primary"
          subtitle="Our Portpolio"
          titleClass="title mb-0"
          title="Services we Developed"
        />
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleProduct
                productClass="event-item"
                productImg={eventImg1}
                productLink="https://bayascript.com"
                productDescription="The first Hausa computer programming language built with JS and C++"
                productDate="Dec 31, 2023"
                productTitle="Bayascript v1.0"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleProduct
                productClass="event-item"
                productImg={eventImg2}
                productLink="https://pypi.org/project/jlod/"
                productDescription="JLOD stands for JSON Local Document Database, its local database for MongoDB"
                productDate="Apr 30, 2020"
                productTitle="JLod v1.0"
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-30">
              <SingleProduct
                productClass="event-item"
                productImg={eventImg3}
                productLink="https://pypi.org/project/jlod/"
                productDescription="FTTCrypt is a software library built for converting large files to small cipher"
                productDate="May 5, 2021"
                productTitle="FTTCrypt v1.0"
              />
            </div>
          </div>
        </div>
      </div>

      <Newsletter
        sectionClass="rs-newsletter style1 orange-color mb--90 sm-mb-0 sm-pb-80"
        titleClass="title mb-0 white-color"
      />

      <Footer
        footerClass="rs-footer home9-style main-home"
        footerLogo={footerLogo}
      />

      {/* scrolltop-start */}
      <ScrollToTop scrollClassName="scrollup orange-color" />
      {/* scrolltop-end */}

      <SearchModal />
    </React.Fragment>
  );
};

export default Portpolio;
