import React from "react";
import {
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";

import Home from "../pages/home";
import About from "../pages/about";
import CourseDetails from "../pages/course/course-single";
import Faq from "../pages/faq";
import Contact from "../pages/contact";
import Error from "../pages/404";
import Course from "../pages/course";
import NewsEvent from "../pages/news-event";
import KidsCourse from "../pages/kids-course";
import InstructorRegister from "../pages/instructor-register";
import CourseRegister from "../pages/course-register";
import InternRegister from "../pages/internship-register";
import SiwesRegister from "../pages/siwes-register";
import Portpolio from "../pages/portfolio";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/" exact element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/about-us" element={<About />} />
      <Route path="/courses" exact element={<Course />} />
      <Route path="/kids/courses" exact element={<KidsCourse />} />
      <Route path="/course/details/:id" element={<CourseDetails />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/portfolio" element={<Portpolio />} />
      <Route path="/contact-us" element={<Contact />} />
      <Route path="/news" element={<NewsEvent />} />
      <Route path="/instructor/register" element={<InstructorRegister />} />
      <Route path="/course/register/:id/:type" element={<CourseRegister />} />
      <Route path="/course/register/:id" element={<CourseRegister />} />
      <Route path="/intern/register" element={<InternRegister />} />
      <Route path="/siwes/register" element={<SiwesRegister />} />
      <Route element={<Error />} />
    </Routes>
  );
};

export default App;
