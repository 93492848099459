const Error = (props) => {
  const { message } = props;

  return (
    <div className="error-text text-center p-12 text-danger">
      <h3 className="error-message mt--6">{message || "Page not found "}</h3>
    </div>
  );
};

export default Error;
