import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import AOS from "aos";
import Tilty from "react-tilty";
import SectionTitle from "../../components/Common/SectionTitle";

// About Images
import shapeImg1 from "../../assets/img/about/icon-shape.png";
import shapeImg2 from "../../assets/img/about/dot-shape.png";
import videoImage from "../../assets/img/kids.jpg";

const KidsInTechSection = () => {
  useEffect(() => {
    AOS.init();
  });

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(!isOpen);

  return (
    <div className="rs-about video-style pt-120 pb-100 md-pt-90 md-pb-70">
      <div className="container relative">
        <div className="icon-shape">
          <Tilty perspective={1200} reverse={true}>
            <img className="shape" src={shapeImg1} alt="shape1" />
          </Tilty>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="img-part media-icon">
              <img className="video-img" src={videoImage} alt="Video Image" />
              <div className="dot-shape">
                <Tilty>
                  <img className="" src={shapeImg2} alt="shape1" />
                </Tilty>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content gray-bg">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-46 md-mb-30"
                subtitleClass="sub-title primary"
                subtitle="Kids in Tech"
                titleClass="title"
                title={
                  <>
                    Giving kids the skills they need to succeed in the 21st
                    century and beyond
                  </>
                }
                descClass="desc pr-36"
                description="We provide intensive, hands-on professional computer development and engineering training for children aged 8-14 from low-income households. Our interactive after-school programs are designed to instill both technological skills and confidence in young learners. We aim to bridge the digital divide and create opportunities for children who might otherwise lack access to cutting-edge technology education. Our comprehensive approach not only teaches practical skills but also fosters a mindset of innovation and problem-solving crucial for success in an increasingly digital world."
                animateName="fade-up"
                animateDuration="1200"
              />
              {/* Section Title End */}
              <div
                className="btn-part zIndex1"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <Link className="readon2" to="/kids/courses">
                  Register Your Kids Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KidsInTechSection;
