import React, { useState } from "react";
import { Link } from "react-router-dom";
import { util } from "../../../utilities/util";
const CourseSidebar = (props) => {
  const { course } = props;

  return (
    <div className="inner-column">
      <div className="intro-video media-icon orange-color2">
        <img
          className="video-img mh-[250px]"
          src={course.thumbnail}
          alt="Video Image"
          onError={util.getDefaultAvatar}
        />
      </div>

      <div className="course-features-info">
        <ul>
          <li className="duration-feature">
            <i className="fa fa-clock-o"></i>
            <span className="label">Duration</span>
            <span className="value">{course.duration}</span>
          </li>

          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label">Completion Certificate</span>
            <span className="value">Yes</span>
          </li>
          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label">Realtime Lectures</span>
            <span className="value">Yes</span>
          </li>
          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label">Assessments</span>
            <span className="value">Yes</span>
          </li>

          {course.category === "MAIN" ? (
            <li className="assessments-feature">
              <i className="fa fa-check-square-o"></i>
              <span className="label">Job Placement</span>
              <span className="value">Yes</span>
            </li>
          ) : (
            <></>
          )}

          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label"> Career Guidance</span>
            <span className="value">Yes</span>
          </li>
          <li className="assessments-feature">
            <i className="fa fa-check-square-o"></i>
            <span className="label"> Beginner Friendly</span>
            <span className="value">Yes</span>
          </li>
        </ul>
      </div>
      <div className="btn-part">
        <h4>Total amount: {util.formatPrice(course.price)}</h4>
        <h5>Tax: {util.formatPrice(0.0)}</h5>
        <div className="mt-5">
          <p>You want pay installment? we have some options for you below</p>
          <Link
            to={`/course/register/${course.id}/quarter`}
            className="btn readon2 bg-danger"
          >
            Pay Quarter {util.formatPrice(Math.round(course.price / 3))}
          </Link>
          <Link
            to={`/course/register/${course.id}/half`}
            className="btn readon2 bg-success"
          >
            Pay Half {util.formatPrice(Math.round(course.price / 2))}
          </Link>
          <Link
            to={`/course/register/${course.id}`}
            className="btn readon2 orange"
          >
            Pay Full Payment {util.formatPrice(Math.round(course.price))}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CourseSidebar;
