import React from "react";
import { Link } from "react-router-dom";

// Image
import teamImg1 from "../../../assets/img/team/1.jpg";
import teamImg2 from "../../../assets/img/team/2.jpg";
import { util } from "../../../utilities/util";
import Error from "../../../components/Error/Error";

const InstructorPart = (props) => {
  const { allocations } = props;
  return (
    <div className="content pt-30 pb-30 pl-30 pr-30 white-bg">
      <div className="row rs-team style1 orange-color transparent-bg clearfix">
        { allocations && allocations.length>0? 
                 allocations.map((allocation, idx) => {
            return (
              <div className="col-lg-6 col-md-6 col-sm-12 sm-mb-30">
                <div className="team-item">
                  <img
                    src={allocation.instructor.dp}
                    alt=""
                    onError={util.getDefaultAvatar}
                  />
                  <div className="content-part">
                    <h4 className="name">
                      <Link href="#">{allocation.instructor.name}</Link>
                    </h4>
                    <span className="designation">
                      {allocation.instructor.specialization}
                    </span>
                    <ul className="social-links">
                      <li>
                        <Link href="#">
                          <i className="fa fa-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <i className="fa fa-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link href="#">
                          <i className="fa fa-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          }):<Error title="Instructor" message="No instructor assigned yet"/>}
      </div>
    </div>
  );
};

export default InstructorPart;
